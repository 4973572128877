<template>
  <div class="checkout-customer" :class="{ open }">
    <AccordionHeader
      class="checkout-customer__header"
      title="Customer"
      :index="index"
      :statusIcon="statusIcon" />
    <div class="checkout-customer__body accordian-body" v-if="open">
      <VeeForm>
        <div class="input-wrapper floating-style">
          <div class="field">
            <Field
              name="name"
              placeholder="Name"
              :rules="nameRules"
              type="text"
              id="name"
              v-model="form.name" />
            <label for="name">Name</label>
          </div>
          <ErrorMessage class="error-message block" name="name" />
        </div>

        <div class="input-wrapper floating-style">
          <div class="field">
            <Field
              name="email"
              placeholder="user@example.com"
              :rules="emailRules"
              id="email"
              v-model="form.email" />
            <label for="email">Email</label>
          </div>
          <ErrorMessage class="error-message block" name="email" />
        </div>

        <div class="input-wrapper floating-style">
          <div class="field">
            <Field
              name="phone"
              placeholder="Phone"
              :rules="phoneRules"
              type="tel"
              id="phone"
              v-model="form.phone" />
            <label for="phone">Phone</label>
          </div>
          <ErrorMessage class="error-message block" name="phone" />
        </div>

        <div class="checkbox-field">
          <label for="save">
            <input type="checkbox" name="save" id="save" v-model="form.save" />
            Save my details for next time
          </label>
          <Icon
            :path="mdiInformation"
            :size="14"
            :tooltip="`This will save your personal details on this computer. If this is a public or shared computer, other people may be able to access these.`" />
        </div>

        <div class="checkbox-field">
          <label for="optIn">
            <input
              type="checkbox"
              name="optIn"
              id="optIn"
              v-model="form.optIn" />
            Please update me with foodie news!
          </label>
        </div>

        <div class="checkbox-field">
          <label for="terms">
            <Field
              type="checkbox"
              name="terms"
              :rules="termsRules"
              id="terms"
              :value="true"
              v-model="form.terms" />
            <p>
              <span class="required-sign">*</span> Accept the
              <a
                target="_blank"
                href="https://www.tucktrucks.co.uk/terms-of-service">
                Terms &amp; Conditions
              </a>
            </p>
          </label>
          <ErrorMessage class="error-message block" name="terms" />
        </div>
      </VeeForm>
    </div>
    <AccordionFooter
      v-if="open"
      class="checkout-customer__footer"
      :forwardDisabled="!validate() || !form.terms"
      @navigate="(ev) => $emit('navigate', ev)" />
  </div>
</template>
<script>
  import store from "@/store";
  import AccordionHeader from "@/components/Accordion/AccordionHeader";
  import AccordionFooter from "@/components/Accordion/AccordionFooter";
  import { mdiFaceMan, mdiCheckCircle, mdiInformation } from "@mdi/js";
  import * as yup from "yup";

  export default {
    data() {
      return {
        serviceId: parseInt(this.$route.params.serviceId),
        cartId: parseInt(this.$route.params.cartId),
        form: {
          name: "",
          email: "",
          phone: "",
          optIn: false,
          save: false,
          terms: false,
        },
        nameRules: yup.string().required("- This is a required field"),
        emailRules: yup
          .string()
          .email("- Please enter a valid email")
          .required("- This is a required field"),
        phoneRules: yup
          .string()
          .min(11, "Must be at least 11 digits")
          .max(17, "Must be 17 digits or less")
          .required("- This is a required field")
          .matches(/^[0-9 +()]+$/, "- Phone number is not valid"),
        termsRules: yup.bool().required("Please accept the terms & conditions"),
        mdiFaceMan,
        mdiCheckCircle,
        mdiInformation,
      };
    },

    props: {
      data: {
        type: Object,
      },
      open: {
        type: Boolean,
      },
      index: {
        type: Number,
      },
    },

    emits: ["navigate"],

    components: {
      AccordionHeader,
      AccordionFooter,
    },

    computed: {
      statusIcon() {
        if (this.validate()) {
          return mdiCheckCircle;
        }

        return mdiFaceMan;
      },

      completed() {
        if (this.data?.cart?.customerId) {
          return this.formValid() && this.form.terms;
        } else {
          return false;
        }
      },
    },

    methods: {
      // [VALIDATE] Fires once when the accordian loads to determine current step. Fires whenever the tab attempts to open.
      validate() {
        if (!store.getters["cart/getQueueIsEnd"]) {
          return false;
        }

        return this.formValid();
      },

      formValid() {
        const { name, email, phone, terms } = this.form;
        const { nameRules, emailRules, phoneRules, termsRules } = this;

        const formDataValidation = {
          isNameValid: nameRules.isValidSync(name),
          isEmailValid: emailRules.isValidSync(email),
          isPhoneValid: phoneRules.isValidSync(phone),
          isTermValid: termsRules.isValidSync(terms),
        };

        return Object.values(formDataValidation).every(Boolean);
      },

      async initialize() {
        this.loadCustomer();
      },

      // [COMPLETE] Fires when moving on to the next step. Return false to prevent navigation.
      async complete() {
        try {
          this.saveCustomer();
        } catch {
          return false;
        }
      },

      loadCustomer() {
        try {
          const customer = JSON.parse(window.localStorage.getItem("customer"));

          this.form.name = customer.name;
          this.form.email = customer.email;
          this.form.phone = customer.phone;

          this.form.optIn = customer.optIn;
          this.form.save = true;
          this.form.terms = this.data.cart.customerId != 0;
        } catch {
          this.form.name = "";
          this.form.email = "";
          this.form.phone = "";

          this.form.optIn = false;
          this.form.save = false;
          this.form.terms = false;
        }
      },

      saveCustomer() {
        if (this.form.save) {
          window.localStorage.setItem(
            "customer",
            JSON.stringify({
              name: this.form.name,
              email: this.form.email,
              phone: this.form.phone,
              optIn: this.form.optIn,
              save: this.form.save,
            })
          );
        } else {
          window.localStorage.removeItem("customer");
        }

        // Fires the network request to update the customer record.
        store.dispatch("cart/updateCustomer", {
          name: this.form.name,
          email: this.form.email,
          phone: this.form.phone,
          optedInMarketing: this.form.optIn == true,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .checkout-customer {
    &__body {
      width: 100%;
    }

    form {
      @include flex;
      width: 100%;

      span.required-sign {
        color: $col_beta-darker;
      }

      .input-wrapper {
        @include flex;
      }

      .error-message {
        font-size: 0.875rem;
        color: $col_beta-darker;
      }

      .input-wrapper,
      label,
      input {
        width: 100%;
      }

      .mdi-icon__tooltip {
        padding: 0.5rem;
      }

      .checkbox-field {
        @include flex($jc: flex-start, $dir: row);
        flex-wrap: wrap;
        margin-top: 0.5rem;
        gap: 0.5rem;
        width: 100%;

        label {
          @include flex($dir: row, $ai: center, $jc: flex-start);
          width: auto;
          font-size: 0.875rem;

          * {
            padding: 0;
            margin: 0;
          }

          input[type="checkbox"] {
            width: 20px;
            height: 20px;
          }

          span.mdi-icon {
            height: 100%;
            display: block !important;
          }
        }
      }
    }
  }
</style>
