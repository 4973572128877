<template>
  <div class="checkout-details" :class="{ open }">
    <AccordionHeader
      class="checkout-details__header"
      title="Details"
      :index="index"
      :statusIcon="statusIcon" />
    <div class="checkout-details__body accordian-body" v-if="open">
      <div class="checkout-details__body__channel">
        <IconButton
          v-if="this.supportsChannel('TableService')"
          text="Table Service"
          @click="selectChannel('TableService')"
          :class="{
            active: this.channel === 'TableService',
          }">
          <template v-slot:icon>
            <Icon :path="mdiTablePicnic" :size="20" />
          </template>
        </IconButton>
        <IconButton
          v-if="this.supportsChannel('ClickCollectAsap')"
          text="Eat Now"
          @click="selectChannel('ClickCollectAsap')"
          :class="{
            active: this.channel === 'ClickCollectAsap',
          }">
          <template v-slot:icon>
            <Icon :path="mdiCursorDefaultClick" :size="20" />
          </template>
        </IconButton>
        <IconButton
          v-if="this.supportsChannel('ClickCollect')"
          text="Collect Later"
          @click="selectChannel('ClickCollect')"
          :class="{
            active: this.channel === 'ClickCollect',
          }">
          <template v-slot:icon>
            <Icon :path="mdiCursorDefaultClickOutline" :size="20" />
          </template>
        </IconButton>
        <IconButton
          v-if="this.supportsChannel('Delivery')"
          text="Delivery"
          @click="selectChannel('Delivery')"
          :class="{
            active: this.channel === 'Delivery',
          }">
          <template v-slot:icon>
            <Icon :path="mdiTruck" :size="20" />
          </template>
        </IconButton>
      </div>

      <div
        v-if="restrictTimeLunch"
        class="checkout-details__body__deliver-location lunch-time-warning">
        <Icon :path="mdiClockAlert" :size="16" />
        <p>
          This order includes a lunchtime meal deal, and so available times are
          limited to
          <span class="lunch-time-warning__highlight">11:30 to 14:30</span>.
        </p>
      </div>

      <div
        class="checkout-details__body__deliver-location"
        v-if="this.channel === 'Delivery'">
        <label for="dropoff-points" v-if="this.channel === 'Delivery'"
          >Select Delivery Location:</label
        >
        <select
          v-model="dropOffPoint"
          id="dropoff-points"
          @change="changeDeliveryLocation">
          <option :value="0">Select Location</option>
          <option
            v-for="dropOffPoint in dropOffPoints"
            :key="dropOffPoint.id"
            :value="dropOffPoint.id">
            {{ dropOffPoint.name }}
          </option>
        </select>
      </div>

      <div
        class="checkout-details__body__selected-slot"
        v-if="getTimingMode == APICART_TIMINGMODE.SELECTED_SLOT">
        <label for="slots" v-if="this.channel === 'Delivery'"
          >Select Delivery Time:</label
        >
        <label for="slots" v-else>Select Collection Time:</label>

        <select
          id="slots"
          @change="changeSelectedSlot"
          :disabled="
            (channel === 'Delivery' && !dropOffPoint) ||
            ifCartChannelUpdating ||
            !slotsLoaded ||
            serviceEnded ||
            (slotsLoaded && slots.length === 0)
          ">
          <option value="" v-if="serviceEnded">
            {{ channel }} service has already ended.
          </option>
          <option
            value=""
            v-if="slots.length === 0 && slotsLoaded && !serviceEnded">
            No slots available
          </option>
          <option
            value=""
            v-if="channel === 'Delivery' && !dropOffPoint && !serviceEnded">
            Please select a drop off point
          </option>
          <option
            value=""
            v-if="ifCartChannelUpdating || (!slotsLoaded && !serviceEnded)">
            Loading {{ formatChannel(channel) }} slots...
          </option>

          <option value="" v-else>Select Slot</option>
          <option
            v-for="slot in getSlots"
            :key="slot.id"
            :value="slot.value"
            :selected="this.selectedSlot == slot.value">
            {{ slot.name }}
          </option>
        </select>
      </div>

      <div
        class="checkout-details__body__queue-length"
        v-if="this.data?.service?.options?.schedulingMode == 'Dynamic'">
        <span class="message" v-if="this.queueData == null"
          >Getting queue information...
          <LoadingSpinner size="1.2rem" color="#2eae96"
        /></span>
        <span class="message" v-else
          >You are <span class="data">{{ getQueuePosition() }}</span> in line,
          with an approximate wait of
          <span class="data">{{ getQueueLength() }}</span
          >. <Icon :path="mdiAsterisk" :size="11" />
          <div class="note">
            <Icon :path="mdiAsterisk" :size="7" />
            Queue positions and timings are approximate.
          </div></span
        >
      </div>

      <div
        class="checkout-details__body__table-number"
        v-if="channel == 'TableService'">
        <label for="table-number">Table Number:</label>
        <input
          id="table-number"
          type="text"
          maxlength="8"
          :value="tableNumber"
          @change="(e) => changeTableNumber(e.target.value)" />
      </div>

      <div
        class="on-premises"
        v-if="
          this.data?.service?.allowEatOnPremises &&
          channel !== 'Delivery' &&
          channel !== 'TableService'
        ">
        <div class="on-premises__buttons">
          <button
            :disabled="eatOnPremises"
            :class="{ active: eatOnPremises }"
            @click="updateEatOnPremises(true)">
            <span class="icon">
              <Icon :path="mdiFoodForkDrink" :size="16" />
              <Icon
                :path="mdiCheckCircle"
                v-if="eatOnPremises"
                :size="16"
                :class="{ active: eatOnPremises }" />
            </span>
            Eat In
          </button>
          <button
            :disabled="!eatOnPremises"
            :class="{ active: !eatOnPremises }"
            @click="updateEatOnPremises(false)">
            Take Out
            <span class="icon">
              <Icon :path="mdiFoodTakeoutBox" :size="16" />
              <Icon
                :path="mdiCheckCircle"
                v-if="!eatOnPremises"
                :size="16"
                :class="{ active: !eatOnPremises }" />
            </span>
          </button>
        </div>
      </div>

      <NoteTextarea
        v-if="this.data.service?.options?.canLeaveNote"
        @update="updateNote" />
    </div>

    <AccordionFooter
      v-if="open"
      class="checkout-details__footer"
      :forwardDisabled="!validate()"
      @navigate="(ev) => $emit('navigate', ev)" />
  </div>
</template>

<script>
  import store from "@/store";
  import { useToast } from "vue-toastification";
  import AccordionHeader from "@/components/Accordion/AccordionHeader";
  import AccordionFooter from "@/components/Accordion/AccordionFooter";
  import {
    formatChannel,
    APICART_CHANNEL,
    APICART_TIMINGMODE,
  } from "@tucktrucks/platform-base-public";
  import { queuePosition, queueLength } from "@/helpers/queue";
  import IconButton from "@/components/IconButton";
  import {
    mdiCursorDefaultClick,
    mdiCursorDefaultClickOutline,
    mdiTruck,
    mdiPencil,
    mdiCheckCircle,
    mdiClockAlert,
    mdiFoodForkDrink,
    mdiFoodTakeoutBox,
    mdiTablePicnic,
    mdiAsterisk,
  } from "@mdi/js";
  import { mapGetters } from "vuex";
  import NoteTextarea from "./NoteTextarea.vue";

  export default {
    data() {
      return {
        serviceId: parseInt(this.$route.params.serviceId),
        cartId: parseInt(this.$route.params.cartId),
        cartKey: this.$route.params.cartKey,

        dropOffPoints: [],
        slots: [],
        slotsLoaded: false,
        serviceEnded: false,

        channel: null,
        dropOffPoint: null,
        selectedSlot: null,
        queueData: null,
        tableNumber: null,
        restrictTimeLunch: false,
        eatOnPremises: false,
        note: null,

        formatChannel,

        mdiCursorDefaultClick,
        mdiCursorDefaultClickOutline,
        mdiTruck,
        mdiPencil,
        mdiCheckCircle,
        mdiClockAlert,
        mdiFoodForkDrink,
        mdiFoodTakeoutBox,
        mdiTablePicnic,
        mdiAsterisk,

        APICART_TIMINGMODE,
      };
    },

    watch: {
      channel: {
        handler(newValue) {
          if (newValue === "Delivery") {
            this.getDeliveryDropoffPoints();
          }
        },
      },
    },

    props: {
      data: {
        type: Object,
      },
      open: {
        type: Boolean,
      },
      index: {
        type: Number,
      },
    },

    emits: ["navigate", "pollNow"],

    components: {
      AccordionHeader,
      AccordionFooter,
      IconButton,
      NoteTextarea,
    },

    computed: {
      ...mapGetters({
        ifCartChannelUpdating: "cart/getIfCartChannelUpdating",
      }),

      statusIcon() {
        if (this.validate()) {
          return mdiCheckCircle;
        }

        return mdiPencil;
      },

      getSlots() {
        if (this.slots == null || this.slots?.length === 0) {
          return [];
        }

        return this.slots.map(({ range: { timeEnd } }, i) => ({
          id: i,
          name: this.formatTime(timeEnd),
          value: timeEnd,
        }));
      },

      getTimingMode() {
        // This is a temporary fix, the channel and timing mode are disconnected in the front
        //  - these match the back but reengineering would be the correct fix.

        switch (this.channel) {
          default:
            return APICART_TIMINGMODE.SELECTED_SLOT;
          case APICART_CHANNEL.PHONE:
            return APICART_TIMINGMODE.SELECTED_SLOT;
          case APICART_CHANNEL.DELIVERY:
            return APICART_TIMINGMODE.SELECTED_SLOT;
          case APICART_CHANNEL.CLICK_COLLECT:
            return APICART_TIMINGMODE.SELECTED_SLOT;
          case APICART_CHANNEL.CLICK_COLLECT_ASAP:
            return APICART_TIMINGMODE.ASAP;
          case APICART_CHANNEL.WALK_UP:
            return APICART_TIMINGMODE.ASAP;
          case APICART_CHANNEL.TABLE_SERVICE:
            return APICART_TIMINGMODE.ASAP;
        }
      },

      completed() {
        return this.configurationCorrect();
      },
    },

    methods: {
      // [VALIDATE] Fires once when the accordian loads to determine current step. Fires whenever the tab attempts to open.
      validate() {
        if (!store.getters["cart/getQueueIsEnd"]) {
          return false;
        }

        return this.configurationCorrect();
      },

      updateNote(note) {
        this.note = note;
      },

      configurationCorrect() {
        // Check that delivery has been set up if that is the channel
        if (
          this.channel == "Delivery" &&
          (!this.dropOffPoint || (this.selectedSlot ?? "") == "")
        ) {
          return false;
        }

        // Check that a slot has been selected
        if (this.channel == "ClickCollect" && (this.selectedSlot ?? "") == "") {
          return false;
        }

        // Check that a table number has been selected
        if (this.channel == "TableService" && (this.tableNumber ?? "") == "") {
          return false;
        }

        return true;
      },

      async initialize() {
        this.channel = this.data?.cart?.channel ?? "ClickCollect";
        this.dropOffPoint = this.data?.cart?.dropOffPoint ?? 0;
        this.selectedSlot = this.data?.cart?.selectedSlot ?? null;
        this.eatOnPremises = this.data?.cart?.eatOnPremises ?? false;
        this.tableNumber = this.data?.cart?.tableNumber ?? null;
        this.note = this.data?.cart?.note ?? null;
      },

      // [SETUP] Fires once at the start if this tab is open.
      enter() {
        if (this.supportsChannel("Delivery")) {
          this.getDeliveryDropoffPoints();
        }
      },

      async leave() {
        if (this.note != null)
          await store.state.apiPublic.client.endpoints.cartNotes.update(
            this.note,
            this.cartId,
            this.cartKey
          );

        // if (this.selectedSlot ?? "" == "") {
        //   this.selectedSlot = this.slots[0];
        //   store.dispatch("cart/updateSelectedSlot", this.selectedSlot);
        // }
      },

      async getDeliveryDropoffPoints() {
        const response =
          await store.state.apiPublic.client.endpoints.services.getDeliveryDropoffPoints(
            this.serviceId
          );

        if (response.status >= 200 && response.status <= 204) {
          this.dropOffPoints = response.data.data;
        } else {
          useToast().error("Could not find delivery locations.");
        }
      },

      // [POLL] Fires immediately after setup, and then every 5 seconds after.
      async poll() {
        this.slotsLoaded = false;

        if (this.channel == "Delivery" && !this.dropOffPoint) {
          return;
        }

        switch (this.getTimingMode) {
          case APICART_TIMINGMODE.ASAP: {
            const response =
              await store.state.apiPublic.client.endpoints.services.getNextTimeforCart(
                this.serviceId,
                this.cartId
              );

            if (response.status == 200) {
              this.queueData = response.data.data;
            } else {
              this.queueData = null;
            }

            break;
          }

          case APICART_TIMINGMODE.SELECTED_SLOT: {
            try {
              const response =
                await store.state.apiPublic.client.endpoints.services.getSlots(
                  this.serviceId,
                  this.cartId
                );

              if (response.status >= 200 && response.status <= 204) {
                this.slots = response.data.data;
                this.restrictTimeLunch =
                  response.data.meta["restrict-time-lunch"];

                // if ((this.selectedSlot ?? "") == "") {
                //   this.selectedSlot = this.slots[0];
                //   store.dispatch("cart/updateSelectedSlot", this.selectedSlot);
                // }
              } else if (response.status === 400) {
                // service ended
                this.slots = [];
                this.serviceEnded = true;
              } else {
                return Promise.reject("Could not get slots.");
              }
            } catch (error) {
              this.slots = [];
              window.log.info(
                `[🛒] Failed to get slots using cart ${this.cartId} for service ${this.serviceId}`,
                error
              );
            } finally {
              this.slotsLoaded = true;
            }

            break;
          }
        }
      },

      selectChannel(channel) {
        this.slotsLoaded = false;
        this.slots = [];

        store.dispatch("cart/updateChannel", channel).then(() => {
          this.channel = channel;

          this.$emit("pollNow");
        });
      },

      changeDeliveryLocation(ev) {
        const location = ev.target.selectedOptions[0].value;

        if (location == 0) {
          return (this.dropOffPoint = 0);
        }

        store.dispatch("cart/updateDeliveryDropOff", location).then(() => {
          this.dropOffPoint = location;
          this.$emit("pollNow");
        });
      },

      changeSelectedSlot(ev) {
        const slot = ev.target.selectedOptions[0].value;

        if (slot == "") {
          return (this.selectedSlot = "");
        }

        store.dispatch("cart/updateSelectedSlot", slot).then(() => {
          this.selectedSlot = slot;
          this.$emit("pollNow");
        });
      },

      changeTableNumber(number) {
        const tableNumber = number.toString();
        store.dispatch("cart/updateTableNumber", tableNumber).then(() => {
          this.tableNumber = tableNumber;
        });
      },

      supportsChannel(channel) {
        try {
          return (
            this.data.service.channels.filter((c) => c.name === channel)
              .length > 0
          );
        } catch {
          return false;
        }
      },

      updateEatOnPremises(eatOnPremises) {
        store
          .dispatch("cart/updateEatOnPremises", eatOnPremises)
          .then((result) => {
            this.eatOnPremises = result;
          });
      },

      getQueuePosition() {
        return queuePosition(this.queueData.position);
      },

      getQueueLength() {
        return `${queueLength(this.queueData.waitMinutes)} minutes`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .checkout-details__body {
    @include flex($g: 1rem, $jc: flex-start);

    &__channel {
      @include flex($dir: row, $jc: flex-start);

      button {
        @include flex($g: 0, $ai: center);
        @include outlined-button($col: $col_gray, $dir: column);
        flex: 1;
        min-width: fit-content;

        &.active {
          @include outlined-button(
            $dir: column,
            $activeCol: $col_beta-darker,
            $col: $col_beta-darker
          );
        }
      }
    }

    &__selected-slot {
      p {
        font-size: 0.875rem;
        &.note {
          color: gray;
          font-style: italic;
          height: 48px;
        }
      }
    }

    &__channel,
    &__table-number,
    &__deliver-location,
    &__selected-slot,
    &__queue-length,
    &__note {
      width: 100%;
    }

    label {
      font-size: 0.875rem;
      margin: 1rem 0;
    }

    select {
      @include select($h: 3rem);
      text-align: center;
    }

    &__deliver-location {
      &.lunch-time-warning {
        @include flex($dir: row);

        p {
          margin-top: 0;
        }

        .icon-wrapper {
          color: $col_beta-darker;
        }

        .lunch-time-warning__highlight {
          font-weight: 900;
        }
      }
    }

    &__table-number {
      label {
        margin-right: 1rem;
      }
      input {
        background: none;
        border: 0;
        border-bottom: 1px solid #ccc;
        font-family: inherit;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 0.5rem 0;
        cursor: text;
        font-size: 1rem;
        &:focus {
          outline: $col_alpha;
        }
      }
    }

    &__queue-length {
      padding: 0.5rem 0.75rem;
      @include flex($dir: row, $jc: flex-start, $wrap: wrap);
      color: $col_alpha-darker;
      border-radius: $card_radius;
      background: #f1f4f2;

      .message {
        .data {
          font-family: "Stolzl Medium";
          font-weight: 900;
        }

        .icon-wrapper {
          position: absolute;
        }
        .loading-spinner {
          margin-top: -25px;
        }

        .note {
          font-size: 0.6em;
          text-align: right;

          .icon-wrapper {
            margin-left: -10px;
          }
        }
      }
    }
  }

  .on-premises {
    display: block;
    width: 100%;

    &__buttons {
      display: flex;
      width: 100%;

      button {
        @include outlined-button($p: 0.5rem, $g: 0.25rem, $h: 3rem);
        flex-grow: 1;
        width: 50%;
        min-width: fit-content;

        span.icon {
          position: relative;
          display: block;
          .active {
            position: absolute;
            color: $col_alpha;
            border-radius: 50%;
            background: white;
            bottom: 0;
            right: -15%;
          }
        }

        &:first-child {
          border-radius: 32px 0 0 32px;
          border-right: 0 !important;
          .icon {
            .active {
              left: -25%;
              right: unset;
            }
          }
        }

        &:last-child {
          border-radius: 0 32px 32px 0;
        }
      }
    }
  }
</style>
