<template>
  <div class="checkout-cart" :class="{ 'checkout-cart_active': isActive }">
    <div class="checkout-cart-header" ref="checkoutCartHeader">
      <div class="checkout-cart-header__icon">
        <Icon :path="mdiCart" :size="20" class="cart-icon" />
        Cart
      </div>
      <Icon
        :path="mdiClose"
        :size="22"
        @click="$emit('closeCart')"
        class="close-icon" />
    </div>
    <CartSummary
      ref="cartSummary"
      :readOnly="true"
      :hideOrderDetails="false"
      :serviceId="serviceId"
      :donationScreen="true" />
  </div>
</template>

<script>
  import CartSummary from "@/components/CartSummary";
  import { mdiCart, mdiClose } from "@mdi/js";

  export default {
    components: {
      CartSummary,
    },
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      isActive: function () {
        if (this.isActive && this.activeFirstFired) {
          const summaryBody = this.$refs["cartSummary"].$refs["summaryBody"];
          const tableHeight =
            this.$refs["cartSummary"].$refs["summaryTable"].clientHeight;
          const checkoutCartHeaderHeight =
            this.$refs["checkoutCartHeader"].clientHeight;
          const tfootHeight =
            this.$refs["cartSummary"].$refs["tfoot"].clientHeight;

          const summaryBodyMaxHeight =
            tableHeight - tfootHeight - checkoutCartHeaderHeight + "px";
          this.summaryBodyMaxHeight = summaryBodyMaxHeight;

          summaryBody.style.maxHeight = summaryBodyMaxHeight;

          this.activeFirstFired = false;
        }

        document.body.style.overflow = this.isActive ? "hidden" : "auto";
      },
    },
    data() {
      return {
        serviceId: this.$route.params.serviceId,
        cartId: this.$route.params.cartId,
        activeFirstFired: true,
        summaryBodyMaxHeight: null,
        mdiCart,
        mdiClose,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .checkout-cart {
    padding: 1rem !important;
    box-shadow: $card_shadow;
    background: $col_white;
    border-radius: $section_radius;
    height: max-content;
    position: sticky;
    width: 400px;

    .mobileLayout & {
      border-radius: 0;
      box-shadow: $popup_shadow;
      display: block;
      min-height: 60vh;
      width: 100%;
      position: fixed;
      bottom: -100%;
      left: 0;
      z-index: 12;
      overflow-y: auto;
      transition: all 0.15s;
      transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
      top: unset;

      &_active {
        bottom: 0;
      }

      @media screen and (max-width: $mobile_max_width) {
        table.cart-summary {
          margin-bottom: 1rem;
        }
      }
    }

    .cart-icon {
      margin-right: 0.15rem;
    }

    .close-icon {
      display: none;

      .mobileLayout & {
        display: block;
        cursor: pointer;
      }
    }

    .checkout-cart-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      &__icon {
        display: flex;
        align-items: center;
        font-weight: 600;

        .mobileLayout & {
          font-size: 0.875rem;
        }
      }
    }

    .cart-item-component-alterations {
      margin-bottom: -0.5rem;

      > ul {
        margin-top: 0.25rem;
      }
    }
  }
</style>
