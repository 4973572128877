<template>
  <div
    class="accordion-header"
    :style="{ 'margin-left': (index - 1) * 25 + '%' }"
    @click="$emit('click')">
    <Icon
      class="accordion-header__status"
      :path="statusIcon"
      :size="18"
      v-if="statusIcon != null && statusIcon != ''" />
    <span class="accordion-header__title">{{ title }}</span>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },

    props: {
      title: {
        type: String,
      },
      statusIcon: {
        type: String,
      },
      index: {
        type: Number,
      },
    },

    emits: ["click"],
  };
</script>

<style lang="scss" scoped>
  .accordion-header {
    @include flex($dir: column, $ai: center, $jc: flex-start, $g: 0);
    width: 25%;
    border-bottom: 5px solid $col_disabled_gray;
    padding: 0.5rem 0 !important;
    color: $col_gray;

    &__title {
      font-size: 0.75rem;
    }

    .open & {
      border-color: $col_beta-darker;
      color: black;
    }

    @media screen and (min-width: 960px) {
      margin-left: 0 !important;
      width: 100%;
      padding-left: 1rem !important;
      @include flex($dir: row, $ai: center, $jc: flex-start);

      &__title {
        font-size: 0.875rem;
      }
    }
  }
</style>
