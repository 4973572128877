import store from "@/store";
import { cartDataGet } from "@/store/public/cart";

export const cartDataAddPaymentIntent = (serviceId, intent) => {
  const cartDataKey = `s${serviceId}`;

  let cartData = cartDataGet(serviceId);
  cartData.intent = intent;

  window.localStorage.setItem(cartDataKey, JSON.stringify(cartData));

  return cartData;
};

export const confirmCart = async (cartId, cartKey, supressWarnings = false) => {
  // If the cart total value is 0, we don't need to check if payments already cover it.
  let total = store.state.cart.summary.total;

  if (total <= 0 && supressWarnings) {
    return {
      cartId: cartId,
      action: "Ignore",
    };
  }

  // -------

  // Verify if the given cart is funded yet.
  let response =
    await store.state.apiPublic.client.endpoints.cartFunding.confirm(
      cartId,
      cartKey,
      supressWarnings ? "warning-logs=supress" : undefined
    );

  // Check to see the response is within expected criteria.
  // If the server returns i.e. 500 this should return a Failed response.
  if (
    !(
      (response.status >= 200 && response.status <= 204) ||
      (response.status >= 400 && response.status <= 402)
    )
  ) {
    response.status = "Failed";
  }

  let data = response.data?.data;

  // If there is no data in the payload, return a support issue.
  if (data == null) {
    return {
      action: "Support",
    };
  }

  // The basic return model, before processing.
  let result = {
    cartId: cartId,
    status: data.status,
    orderId: data.orderId,
    orderNumber: data.number,
    secret: data.secret,
  };

  // Attach an Action to the model to help determine what to do.
  switch (data.status) {
    case "NewOrder":
    case "AlreadyExists":
      // If a new order is created or one already exists, we should redirect to the status screen.
      result.action = "ShowOrder";
      break;
    case "UnsatisfiedIntentsCover":
      // If there are intents already present but they're not satisfied, inform the user.
      result.action = "Satisfy";
      break;
    case "IntentsDoNotCover":
      // If there are not intents present, create them and ask for payment.
      result.action = "Pay";
      break;
    default:
      // Something went wrong, contact support.
      result.action = "Support";
      break;
  }

  return result;
};

export const createStripeIntent = async (cartId, cartKey, serviceId) => {
  try {
    let response =
      await store.state.apiPublic.client.endpoints.paymentsStripe.createIntent(
        cartId,
        cartKey
      );

    if (response.status >= 200 && response.status <= 204) {
      // Store the intent secret against the cart data ready to reclaim
      cartDataAddPaymentIntent(
        serviceId,
        response.data.data.stripeClientSecret
      );

      return response.data.data.stripeClientSecret;
    }
  } catch (err) {
    window.log.error(err);
  }

  return null;
};
