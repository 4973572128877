<template>
  <div class="checkout-payment" :class="{ open }">
    <AccordionHeader
      class="checkout-payment__header"
      title="Payment"
      :index="index"
      :statusIcon="statusIcon" />
    <div class="checkout-payment__body accordian-body" v-if="open">
      <div class="checkout-payment__body__stripe" v-if="paymentRequired">
        <div class="checkout-payment__body__stripe__header">
          <img
            alt="Stripe logo"
            class="payment-header__logo"
            :src="
              this.getCdnFile('/images/logos/stripe/stripe-logo-slate.svg')
            " />
        </div>
        <div v-if="!stripeShown">Loading Stripe Payment Form...</div>
        <form
          class="checkout-payment__body__stripe__form"
          id="payment-form"
          v-show="stripeLoaded">
          <div id="payment-online" style="display: none">
            <div id="payment-request-button">
              <!-- A Stripe Element will be inserted here. -->
            </div>

            <h4>Or pay securely with your card details:</h4>
          </div>

          <div id="payment-card" style="display: none">
            <div id="payment-element">
              <!-- Elements will create form elements here -->
            </div>
          </div>

          <div id="error-message">
            <!-- Display error message to your customers here -->
          </div>

          <div v-if="this.data?.service?.status == 'Finished'">
            <h3>Unable to Check Out</h3>
            <p>
              This service has now finished, and no more orders can be placed.
            </p>
          </div>

          <div v-if="this.data?.service?.status == 'Cancelled'">
            <h3>Unable to Check Out</h3>
            <p>
              This service has been cancelled, and no more orders can be placed.
            </p>
          </div>
        </form>
      </div>
      <div class="checkout-payment__body__no-payment-required" v-else>
        <h4>Your cart total is £0,<br />and no payment is required.</h4>
      </div>
    </div>
    <AccordionFooter
      v-if="open"
      class="checkout-payment__footer"
      :forwardDisabled="paymentRequired ? !validate() : false"
      :forwardText="paymentRequired ? 'Checkout' : 'Confirm'"
      @navigate="(ev) => $emit('navigate', ev)" />
  </div>
</template>
<script>
  import axios from "axios";
  import store from "@/store";
  import AccordionHeader from "@/components/Accordion/AccordionHeader";
  import AccordionFooter from "@/components/Accordion/AccordionFooter";
  import { loadStripe } from "@stripe/stripe-js";
  import { confirmCart } from "@/helpers/payments";
  import { createStripeIntent } from "@/helpers/payments";
  import { mdiCreditCardEdit, mdiCheckCircle } from "@mdi/js";

  export default {
    data() {
      return {
        serviceId: parseInt(this.$route.params.serviceId),
        cartId: parseInt(this.$route.params.cartId),
        cartKey: this.$route.params.cartKey,
        stripeKey: null,
        clientSecret: null,
        stripeShown: false,
        stripeLoaded: false,
        stripeLoadedDelayed: false,
        applePayAvailable: false,
        googlePayAvailable: false,
        linkPayAvailable: false,
        mdiCreditCardEdit,
        mdiCheckCircle,
      };
    },

    watch: {
      open(newValue) {
        if (newValue) {
          this.stripeShown = false;
        }
      },
    },

    props: {
      data: {
        type: Object,
      },
      open: {
        type: Boolean,
      },
      index: {
        type: Number,
      },
    },

    emits: ["navigate", "setLoadMessage"],

    components: {
      AccordionHeader,
      AccordionFooter,
    },

    computed: {
      statusIcon() {
        if (this.validate()) {
          return mdiCheckCircle;
        }

        return mdiCreditCardEdit;
      },

      paymentRequired() {
        return this.data?.summary?.total > 0;
      },

      completed() {
        return this.paymentRequired ? this.paymentTaken : false;
      },
    },

    methods: {
      async initialize() {
        await this.getStripePublicKey();
      },

      // [VALIDATE] Fires once when the accordian loads to determine current step. Fires whenever the tab attempts to open.
      validate() {
        if (!store.getters["cart/getQueueIsEnd"]) {
          return false;
        }

        return this.paymentRequired ? this.stripeShown : true;
      },

      // [ENTER] Fires once at the start if this tab is open.
      async enter() {
        if (this.paymentRequired) {
          this.clientSecret = await createStripeIntent(
            this.cartId,
            this.cartKey,
            this.serviceId
          );

          this.stripe = await loadStripe(this.stripeKey, {
            apiVersion: "2022-08-01",
          });

          this.elements = this.stripe.elements({
            clientSecret: this.clientSecret,
            appearance: {
              theme: "flat",

              variables: {
                colorPrimary: "#ffa6a6",
                colorBackground: "#ffffff",
                colorText: "#30313d",
                colorDanger: "#df1b41",
                fontFamily: "sans-serif",
                spacingUnit: "4px",
                borderRadius: "5px",
              },

              rules: {
                ".Input": {
                  padding: "20px 10px",
                },
              },
            },
          });

          await this.setStripe();
        }
      },

      // [COMPLETE] This method fires when the continue button is pressed. If false is returned, continue will be blocked.
      async complete() {
        if (!this.paymentRequired) {
          this.$emit("setLoadMessage", "Confirming order...");
          this.confirmPaymentSuccess(true);

          return;
        }

        window.log.info("[💷] Sending payment information to Stripe.");
        this.$emit("setLoadMessage", "Connecting to Stripe...");

        this.confirmStripePayment(true);
      },

      async setStripe() {
        const checkout = this;

        this.stripeLoaded = false;
        this.stripeLoadedDelayed = false;
        this.stripeShown = false;

        // ----------------------------------------------------------------------------------------
        // Setup payment request buttons, i.e. Google Pay, Apple Pay etc.
        const paymentRequest = this.getPaymentRequest();
        const paymentRequestButton =
          this.getPaymentRequestButton(paymentRequest);
        paymentRequest.on("paymentmethod", (ev) => {
          this.processPaymentRequest(ev);
        });
        paymentRequest
          .canMakePayment()
          .then((result) =>
            this.checkPaymentRequestAvailability(
              result,
              checkout,
              paymentRequestButton
            )
          );

        // ----------------------------------------------------------------------------------------
        // Setup payment element, i.e. manual card details.
        this.paymentElement = this.elements.create("payment");
        this.paymentElement.on("ready", () => {
          this.stripeShown = true;
        });
        this.paymentElement.on("change", (event) => {
          if (event.value.type === "card") {
            this.stripePaymentReady = event.complete;
          } else {
            this.stripePaymentReady = true;
          }
        });
        this.paymentElement.mount("#payment-element");

        const paymentForm = document.getElementById("payment-form");
        paymentForm.addEventListener("submit", () =>
          this.confirmStripePayment(true)
        );

        this.stripeLoaded = true;

        // Delay "stripeLoaded" for 2s as it comes up before the form has completely loaded
        window.setTimeout(() => {
          this.stripeLoadedDelayed = true;
        }, 2000);
      },

      async getStripePublicKey() {
        const stripePublicKeyEndpoints =
          store.state.apiPublic.client.getEndpoint("/meta/stripe/public-key");

        const response = await axios.get(stripePublicKeyEndpoints);

        if (response.status >= 200 && response.status <= 204) {
          this.stripeKey = response.data;
        } else {
          this.stripeLoadError =
            "Stripe integration is not currently available.";
        }
      },

      getPaymentRequest() {
        const summary = store.state.cart.summary;

        return this.stripe.paymentRequest({
          country: "GB",
          currency: "gbp",
          total: {
            label: "Order",
            amount: Math.round(summary.total * 100),
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });
      },

      getPaymentRequestButton(paymentRequest) {
        return this.elements.create("paymentRequestButton", {
          paymentRequest: paymentRequest,
          style: {
            paymentRequestButton: {
              type: "default",
              theme: "dark",
              height: "64px",
            },
          },
        });
      },

      checkPaymentRequestAvailability(result, checkout, paymentRequestButton) {
        //window.log.info("💴💵💶💷 Apple/Google Pay", result);
        // Check the availability of the Payment Request API.

        if (result) {
          checkout.applePayAvailable = result.applePay;
          checkout.googlePayAvailable = result.googlePay;
          checkout.linkPayAvailable = result.link;

          paymentRequestButton.mount("#payment-request-button");

          document.getElementById("payment-online").style.display = "block";
          document.getElementById("payment-card").style.display = "block";
        } else {
          checkout.applePayAvailable = false;
          checkout.googlePayAvailable = false;
          checkout.linkPayAvailable = false;

          document.getElementById("payment-online").style.display = "none";
          document.getElementById("payment-card").style.display = "block";
          document.getElementById("payment-request-button").style.display =
            "none";
        }

        return result;
      },

      processPaymentRequest(ev) {
        try {
          const checkout = this;
          const stripe = this.stripe;

          // Confirm the PaymentIntent without handling potential next actions (yet).
          this.stripe
            .confirmCardPayment(
              this.clientSecret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false }
            )
            .then(function (confirmResult) {
              if (confirmResult.error) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                ev.complete("fail");
              } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete("success");
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11"
                // instead check for: `paymentIntent.status === "requires_source_action"`.
                if (confirmResult.paymentIntent.status === "requires_action") {
                  // Let Stripe.js handle the rest of the payment flow.
                  stripe
                    .confirmCardPayment(this.clientSecret)
                    .then(function (result) {
                      if (result.error) {
                        // The payment failed -- ask your customer for a new payment method.

                        checkout.showError("PAY606");
                      } else {
                        // The payment has succeeded.
                        checkout.confirmPaymentSuccess(true);
                      }
                    });
                } else {
                  // The payment has succeeded.
                  checkout.confirmPaymentSuccess(true);
                }
              }
            });
        } catch (ex) {
          window.log.error(ex);

          this.$emit("setLoadMessage", "");
          this.showError("PAY605");

          ev.complete("fail");
        }
      },

      async confirmStripePayment(displayError) {
        try {
          const elements = this.elements;

          const response = await this.stripe.confirmPayment({
            elements,
            confirmParams: {},
            redirect: "if_required",
          });

          if (response.error) {
            if (
              response.error.code === "incomplete" ||
              (response.error.status != "succeeded" &&
                response.paymentIntent == null) ||
              response.paymentIntent.status != "succeeded"
            ) {
              window.log.error(
                "Stripe error: " + response.error.message,
                response
              );
              this.$emit("setLoadMessage", "");
              if (displayError) this.showError("PAY601");

              return Promise.reject(
                "There was an error processing the payment."
              );
            }
          } else {
            this.confirmPaymentSuccess(displayError);
          }
        } catch (ex) {
          window.log.error(ex);
          this.$emit("setLoadMessage", "");
          if (displayError) this.showError("PAY602");

          return Promise.reject("There was an error processing the payment.");
        }
      },

      confirmPaymentSuccess(displayError = false) {
        try {
          this.$emit("setLoadMessage", "Confirming Payment...");

          confirmCart(this.cartId, this.cartKey).then((result) => {
            switch (result.action) {
              case "Support": {
                this.$emit("setLoadMessage", "");

                if (displayError) {
                  this.showError("PAY603");
                }

                break;
              }

              case "ShowOrder": {
                this.$emit("setLoadMessage", "Order Confirmed");

                // Remove the stored cart
                store.dispatch("cart/clear");

                window.setTimeout(() => {
                  this.$emit("setLoadMessage", "");

                  this.$router.push({
                    name: "order-status",
                    params: { orderId: result.orderId, secret: result.secret },
                  });
                }, 1000);

                break;
              }
            }
          });
        } catch (ex) {
          window.log.error(ex);

          this.$emit("setLoadMessage", "");

          if (displayError) {
            this.showError("PAY604");
          }
        }
      },

      showError(code) {
        const messageContainer = document.querySelector("#error-message");

        // TODO: Process all the different states, i.e. payment already made
        messageContainer.textContent =
          "This order could not be processed. Please verify your payment method.";

        window.log.info(
          "[🛒] This order could not be processed. Please verify your payment method. (" +
            code +
            ")"
        );
      },
    },
  };
</script>
<style lang="scss" scoped>
  .checkout-payment {
    width: 100%;

    &__body {
      width: 100%;

      h4 {
        font-size: 0.875rem;
        margin: 1rem auto;
        text-align: center;
      }

      &__stripe {
        width: 100%;
        max-width: 600px;
        margin: 0 auto 6rem;

        color: $col_softblack;

        &__header {
          width: 100%;

          img {
            max-width: 80px;
            width: 30%;
            margin-left: -2%;
          }
        }

        &__form {
          h4 {
            width: 90%;
          }

          #payment-request-button {
            width: 80%;
            max-width: 300px;
            margin: 0 auto;
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 1rem;

            > * {
              width: 100%;
              margin-top: -1rem;
              height: calc(100% - 1rem);
            }
          }
        }
      }

      &__no-payment-required {
        @include flex($ai: center, $jc: center);
        height: 100%;

        h4 {
          @include flex($ai: center, $jc: center);
          height: 100%;
          max-width: 300px;
          flex: 1;
          text-align: center;
          font-size: 1.2rem;
        }
      }
    }

    #error-message {
      color: $col_delta-lighter;
      padding-top: 1rem;
    }
  }
</style>
