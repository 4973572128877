<template>
  <div class="accordion-footer">
    <button
      class="accordion-footer__back"
      :disabled="backDisabled"
      @click="$emit('navigate', -1)">
      <Icon :path="mdiChevronLeft" />
      {{ backText }}
    </button>
    <button
      class="accordion-footer__forward"
      :disabled="forwardDisabled"
      @click="$emit('navigate', 1)">
      {{ forwardText }}
      <Icon :path="mdiChevronRight" />
    </button>
  </div>
</template>

<script>
  import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";

  export default {
    data() {
      return {
        mdiChevronRight,
        mdiChevronLeft,
      };
    },

    props: {
      backDisabled: {
        type: Boolean,
        default: false,
      },
      forwardDisabled: {
        type: Boolean,
        default: false,
      },
      backText: {
        type: String,
        default: "Back",
      },
      forwardText: {
        type: String,
        default: "Continue",
      },
    },

    emits: ["navigate"],
  };
</script>

<style lang="scss" scoped>
  .accordion-footer {
    width: 100%;
    @include flex($dir: row, $g: 0, $ai: stretch);
    border-top: 1px solid $col_beta-darker;
    border-bottom: 1px solid $col_gray;

    > button {
      flex: 1;
    }

    &__back {
      @include contained-button(
        $textCol: $col_beta-darker,
        $bgCol: white,
        $radius: 0
      );
    }

    &__forward {
      @include contained-button($p: 1rem 0.5rem, $radius: 0, $h: 4rem);
    }

    .mobileLayout & {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;

      button {
        height: 4rem;
      }
    }
  }
</style>
