<template>
  <button
    type="button"
    class="back-icon"
    @click="$emit('close')"
    :class="{ 'text-style': ifTextStyle }">
    <Icon :path="mdiChevronLeft" v-if="withIcon" :size="20" />
    <span class="text">{{ title }}</span>
  </button>
</template>

<script>
  import { mdiChevronLeft } from "@mdi/js";

  export default {
    data() {
      return {
        mdiChevronLeft,
      };
    },
    props: {
      title: {
        default: null,
      },
      withIcon: {
        type: Boolean,
        default: false,
      },
      ifTextStyle: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["close"],
  };
</script>

<style lang="scss" scoped>
  .back-icon {
    background: $col_grey_green;
    color: $col_gray;

    &.text-style {
      background: none;
      color: black;
      min-width: auto;
    }

    &:not(.text-style):hover {
      background: $col_beta_lighter;
      color: #fff;
    }
  }
</style>
