<template>
  <div class="item-note">
    <h4><Icon :path="mdiPencil" :size="16" />Note</h4>
    <textarea
      :value="note"
      @input="(e) => updateNote(e.target.value)"
      rows="6"
      placeholder="Note about your order"
      :maxlength="noteMaxLength"></textarea>
    <span class="characters-left" :class="{ red: getNoteCharactersCount < 10 }">
      {{ getNoteCharactersCount }} / {{ noteMaxLength }}
    </span>
  </div>
</template>

<script>
  import { mdiPencil } from "@mdi/js";
  export default {
    data() {
      return { mdiPencil, note: null, noteMaxLength: 250 };
    },

    emits: ["update"],

    computed: {
      getNoteCharactersCount() {
        if (!this.note) return this.noteMaxLength;
        return this.noteMaxLength - this.note.length;
      },
    },

    methods: {
      updateNote(note) {
        this.note = note;
        this.$emit("update", note);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .item-note {
    padding: 0 2rem;
    position: relative;
    width: 100%;

    h4 {
      @include flex($dir: row, $jc: flex-start, $ai: center);
      margin-top: unset;
    }

    textarea {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid $col_gray;
      border-radius: $button_radius;
      font-size: 1rem;
    }

    .characters-left {
      font-size: 0.75rem;
      display: block;
      text-align: right;
      position: absolute;
      width: auto;
      padding: 0.25rem;
      bottom: 1rem;
      right: 2.5rem;
      background: rgb(255 255 255 / 80%);
      border-radius: 0.25rem;

      &.red {
        color: $col_beta-darker;
      }
    }
  }
</style>
