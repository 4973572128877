<template>
  <div class="checkout">
    <div class="checkout__spinner" v-if="loadingMessage || step === 0">
      <LoadingSpinner />
      <span class="checkout__spinner__message">{{ loadingMessage }}</span>
    </div>

    <div class="checkout__view-cart">
      <IconButton
        text="View Cart"
        :path="mdiCart"
        @click="$emit('toggleCartView')"
        class="view-cart" />
    </div>

    <div class="checkout__backlink" v-if="backlink">
      <BackToPageLink
        @close="navigate(-100)"
        title="Back To Menu"
        with-icon
        if-textStyle />
    </div>

    <CheckoutOutlet
      v-if="data?.service"
      :mobileView="mobileView"
      :outlet="data.service.outlet.name"
      :address="data.service?.address" />

    <ul class="checkout__list">
      <li class="checkout__list__item">
        <CheckoutDetails
          :data="data"
          :open="isOpen(1)"
          ref="step1"
          :index="1"
          @navigate="navigate"
          @pollNow="pollNow"
          @setLoadMessage="setLoadMessage" />
      </li>
      <li class="checkout__list__item">
        <CheckoutCustomer
          :data="data"
          :open="isOpen(2)"
          ref="step2"
          :index="2"
          @navigate="navigate"
          @pollNow="pollNow"
          @setLoadMessage="setLoadMessage" />
      </li>
      <li class="checkout__list__item">
        <CheckoutPromotions
          :data="data"
          :open="isOpen(3)"
          ref="step3"
          :index="3"
          @navigate="navigate"
          @pollNow="pollNow"
          @setLoadMessage="setLoadMessage" />
      </li>
      <li class="checkout__list__item">
        <CheckoutPayment
          :data="data"
          :open="isOpen(4)"
          ref="step4"
          :index="4"
          @navigate="navigate"
          @pollNow="pollNow"
          @setLoadMessage="setLoadMessage" />
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import store from "@/store";
  import BackToPageLink from "@/components/BackToPageLink";
  import CheckoutDetails from "@/components/Checkout/CheckoutDetails";
  import CheckoutCustomer from "@/components/Checkout/CheckoutCustomer";
  import CheckoutPromotions from "@/components/Checkout/CheckoutPromotions";
  import CheckoutPayment from "@/components/Checkout/CheckoutPayment";
  import CheckoutOutlet from "@/components/Checkout/CheckoutOutlet";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import IconButton from "../IconButton.vue";
  import { mdiCart } from "@mdi/js";

  export default {
    data() {
      return {
        serviceId: parseInt(this.$route.params.serviceId),
        cartId: parseInt(this.$route.params.cartId),
        data: {},
        step: 0, // Default to zero so we can see this was the entry step.
        steps: [],
        loadingMessage: "Almost there!",
        mdiCart,
        polling: false,
      };
    },

    props: {
      backlink: {
        type: Boolean,
        default: false,
      },
      mobileView: {
        type: Boolean,
      },
    },

    components: {
      BackToPageLink,
      CheckoutDetails,
      CheckoutCustomer,
      CheckoutPromotions,
      CheckoutPayment,
      CheckoutOutlet,
      LoadingSpinner,
      IconButton,
    },

    emits: ["navigate", "toggleCartView", "changeStep"],

    computed: {
      ...mapGetters({
        getCartId: "cart/getCartId",
        getCartKey: "cart/getCartKey",
        getCartSummary: "cart/getSummary",
        networkError: "network/networkError",
      }),
    },

    methods: {
      isOpen(step) {
        return step == this.step;
      },

      async navigate(index) {
        const oldStep = this.step;
        const newStep = this.step + index;

        if (newStep == oldStep) {
          return;
        }

        // stop doing the polling, to ensure order of events.
        this.stopPoll();

        // if we are moving forward by 1, fire the "complete" event.
        if (index == 1 && oldStep > 1) {
          const step = this.steps[oldStep - 1];

          if (step?.complete != null) {
            const confirm = await step.complete();

            // If the complete method returns false, start the poll again and drop out.
            if (confirm === false) {
              this.startPoll();

              return;
            }
          }
        }

        // if we moved back to before the first step, emit navigate -1 and stop
        if (newStep < 1) {
          this.$emit("navigate", -1);
          return;
        }

        // if we moved past the last step, emit navigate 1 and stop
        if (newStep > this.steps.length) {
          this.$emit("navigate", 1);
          return;
        }

        // call the leave method on the old step
        if (oldStep >= 1) {
          if (this.steps[oldStep - 1]?.leave != null)
            await this.steps[oldStep - 1].leave(newStep);
        }

        // call a method on the accordion itself for the step changing.
        // here we can implement logic which is executed on every step.
        this.$emit("changeStep", { from: oldStep, to: newStep });

        // update the current step
        this.step = newStep;

        // call the enter method on the new step
        if (newStep >= 1) {
          if (this.steps[newStep - 1]?.enter != null)
            await this.steps[newStep - 1].enter(oldStep);
        }

        // start up polling with the new step
        this.startPoll();
      },

      getCurrent() {
        return this.steps[this.step - 1];
      },

      async validateSteps() {
        let step1 = this.$refs.step1;
        let step2 = this.$refs.step2;
        let step3 = this.$refs.step3;
        let step4 = this.$refs.step4;
        this.steps = [step1, step2, step3, step4];

        // Run validation on all steps.
        await this.steps.forEach(async (step) => {
          if (step !== null && step?.initialize != null)
            await step.initialize();
        });

        await this.steps.forEach(async (step) => {
          if (step !== null && step?.validate != null) await step.validate();
        });

        // Find the last step which is not valid.
        for (let i = 1; i <= 4; i++) {
          if (this.steps[i - 1] !== null && !this.steps[i - 1].completed) {
            return i;
          }
        }

        return this.steps.length;
      },

      startPoll() {
        if (this.job != null) {
          this.stopPoll();
        }

        this.job = window.setIntervalAndExecute(async () => {
          if (this.networkError() || this.polling) return;

          if (this.getCurrent()?.poll != null) {
            this.polling = true;
            await this.getCurrent().poll();
            this.polling = false;
          }
        }, 5000);
      },

      stopPoll() {
        window.clearInterval(this.job);
        this.job = null;

        this.polling = false;
      },

      pollNow() {
        this.stopPoll();
        this.startPoll();
      },

      setLoadMessage(message) {
        this.loadingMessage = message ?? "Loading";
      },

      async loadData() {
        // Select the current service, this will locate any existing cart, then load all data into the object
        store.dispatch("cart/selectService", this.serviceId);

        this.data = {};

        let summaryResponse =
          await store.state.apiPublic.client.endpoints.cartStatus.validate(
            this.getCartId,
            this.getCartKey
          );

        let cartResponse =
          await store.state.apiPublic.client.endpoints.carts.getbyId(
            this.cartId
          );

        let serviceResponse =
          await store.state.apiPublic.client.endpoints.services.getbyId(
            this.serviceId
          );

        if (summaryResponse.status >= 200 && summaryResponse.status <= 204) {
          this.data.summary = summaryResponse.data.data;
        }

        if (cartResponse.status >= 200 && cartResponse.status <= 204) {
          this.data.cart = cartResponse.data.data;
        }

        if (serviceResponse.status >= 200 && serviceResponse.status <= 204) {
          this.data.service = serviceResponse.data.data;
        }
      },
    },

    async mounted() {
      await this.loadData();

      const step = await this.validateSteps();
      this.loadingMessage = "";
      await this.navigate(step);
    },

    unmounted() {
      this.stopPoll();
    },
  };
</script>

<style lang="scss">
  .checkout {
    position: relative;

    &__spinner {
      @include flex($ai: center, $jc: center, $g: 2rem);
      height: 100%;
      position: absolute;
      background: rgb($col_offwhite, 85%);
      text-align: center;
      padding: 1rem;
      backdrop-filter: blur(2px);
      padding: 1rem;
      width: 100%;
      z-index: 8;

      .loading-spinner {
        width: 2rem;
        height: 2rem;
      }

      &__message {
        font-size: 1.25rem;
      }
    }

    &__view-cart {
      display: none;
    }

    &__list {
      margin-top: 0;

      &__item {
        > div {
          width: 100%;
          @include flex($g: 0);

          > div {
            flex: 1;

            &.accordian-body {
              padding: 1rem;
              flex: 2;
              width: 100%;
              overflow: auto;
              background: $col_offwhite;
            }
          }
        }
      }
    }

    @media screen and (max-width: 960px) {
      @include flex($jc: flex-start, $ai: initial, $g: 0);

      &__backlink {
        padding-top: 0.5rem;
      }

      &__view-cart {
        display: flex;
        position: absolute;
        right: 1.5rem;
        top: 1rem;

        button {
          flex-direction: row !important;
          font-size: 0.875rem !important;
          padding: 0 !important;
          gap: 0.25rem;
          min-width: auto !important;

          &:hover {
            color: black !important;
            background: none !important;
          }
        }
      }

      &__list {
        position: relative;
        @include flex;
        flex: 2;

        overflow: auto;
        margin-bottom: 8rem;

        &__item {
          position: absolute;
          top: 0;
          width: 100%;

          &:has(.open) {
            height: 100%;
          }

          > div {
            height: 100%;

            .accordion-header,
            .accordion-footer {
              flex: 0;
            }
          }
        }
      }
    }
  }
</style>
