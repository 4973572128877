<template>
  <section class="checkout-wrapper">
    <Checkout
      :backlink="true"
      :mobileView="mobileView"
      @navigate="navigate"
      @changeStep="changeStep"
      @toggleCartView="toggleCartView"
      ref="checkout" />
    <Transition name="fade">
      <CheckoutCart
        v-if="!mobileView || cartIsActive"
        :isActive="!mobileView || cartIsActive"
        :readOnly="true"
        @closeCart="cartIsActive = false" />
    </Transition>
  </section>
</template>

<script>
  import store from "@/store";
  import Checkout from "@/components/Checkout/Checkout.vue";
  import CheckoutCart from "@/components/Checkout/CheckoutCart";
  import { confirmCart } from "@/helpers/payments";

  export default {
    data() {
      return {
        serviceId: parseInt(this.$route.params.serviceId),
        cartId: parseInt(this.$route.params.cartId),
        cartKey: this.$route.params.cartKey,
        cartIsActive: false,
        mobileView: false,
      };
    },

    components: {
      Checkout,
      CheckoutCart,
    },

    computed: {
      paymentRequired() {
        return this.$refs.checkout.data?.summary?.total > 0;
      },
    },

    methods: {
      navigate(index) {
        if (index < 0) {
          this.$router.push({
            name: "service",
            serviceId: this.serviceId,
          });
        } else {
          // TODO: get the order details and redirect
          // const orderDetails = this.$refs.checkout.getOrderDetails();
          // this.$router.push({
          //   name: "order-status",
          //   orderId: orderDetails.orderId,
          //   secret: orderDetails.secret,
          // });
        }
      },

      changeStep(step) {
        if (step.from != step.to)
          if (this.paymentRequired) {
            confirmCart(this.cartId, this.cartKey).then((result) => {
              switch (result.action) {
                case "ShowOrder": {
                  // Remove the stored cart and redirect to the order.
                  store.dispatch("cart/clear");
                  this.$router.push({
                    name: "order-status",
                    params: { orderId: result.orderId, secret: result.secret },
                  });
                }
              }
            });
          }
      },

      toggleCartView() {
        this.cartIsActive = !this.cartIsActive;
      },

      resetViewStatus() {
        this.mobileView = window.innerWidth < 960;
      },
    },

    mounted() {
      this.resetViewStatus();
      window.addEventListener("resize", this.resetViewStatus);
    },
    unmounted() {
      window.removeEventListener("resize", this.resetViewStatus);
    },
  };
</script>

<style lang="scss" scoped>
  .checkout-wrapper {
    @include flex($dir: row, $g: 1rem);
    padding: 2rem !important;
    flex: 1;
    max-width: 1100px;
    margin: 0 auto;

    > .checkout-cart {
      flex: 1;
    }

    > .checkout {
      flex: 2;
      box-shadow: $card_shadow;
      border-radius: $section_radius;
      overflow: hidden;
    }

    @media screen and (max-width: 960px) {
      flex-direction: column;
      width: 100%;
      padding: 0 !important;
      margin: 0 !important;
      background: white;

      > .checkout-cart {
        position: fixed;
        width: 100%;
        bottom: 0;
        display: none;

        &_active {
          display: block;
        }
      }

      > .checkout {
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        position: fixed;
        top: 60px;
        height: 100%;
      }
    }
  }
</style>
