<template>
  <div class="checkout-outlet" :class="{ mobileView: mobileView }">
    <h5 class="name">
      <Icon :path="mdiTruck" :size="16" /><span>{{ outlet }}</span>
    </h5>
    <h5>
      <Icon :path="mdiMapMarker" :size="16" />
      <span v-if="!mobileView"> {{ fullAddress }}</span>
      <span v-else @click="showAllAddress = !showAllAddress">
        {{
          showAllAddress
            ? address.postalCode + ", " + address.stateProvince
            : fullAddress
        }}
        <span class="clickable">
          <template v-if="showAllAddress">Show all</template>
          <template v-else>Show less</template>
        </span>
      </span>
    </h5>
  </div>
</template>

<script>
  import { formatAddress } from "@tucktrucks/platform-base-public";
  import { mdiTruck, mdiMapMarker } from "@mdi/js";

  export default {
    data() {
      return {
        mdiTruck,
        mdiMapMarker,
        showAllAddress: false,
      };
    },

    computed: {
      fullAddress() {
        return formatAddress(this.address);
      },
    },

    props: {
      outlet: { type: String },
      address: { type: Object },
      mobileView: {
        type: Boolean,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .checkout-outlet {
    padding: 0.5rem 0.75rem;
    margin: 0 1rem;
    @include flex($dir: row, $jc: flex-start, $wrap: wrap);
    color: $col_alpha-darker;
    border-radius: $card_radius;
    background: #f1f4f2;

    &.mobileView {
      background: $col_light_grey_green;
    }

    h5 {
      font-size: 0.875rem;
      line-height: 1.2em;
      @include flex($dir: row, $ai: flex-start, $g: 0.25rem);
      &.name {
        flex-shrink: 0;
      }
      .clickable {
        text-decoration: underline;
        color: gray;
        font-size: 0.75rem;
      }
    }
  }
</style>
