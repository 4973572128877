<template>
  <div class="checkout-promotion" :class="{ open }">
    <AccordionHeader
      class="checkout-promotion__header"
      title="Promotions"
      :index="index"
      :statusIcon="statusIcon" />
    <div class="checkout-promotion__body accordian-body" v-if="open">
      <form @submit.prevent="validate()">
        <div class="input-wrapper floating-style">
          <Icon
            :path="mdiTag"
            :size="22"
            fillColor="#0b7162"
            class="voucher__icon" />
          <div class="field">
            <input
              type="text"
              v-model="form.voucherCode.value"
              placeholder="Voucher Code"
              id="voucherCode"
              name="voucherCode" />
            <label for="voucherCode">Voucher Code</label>
          </div>
          <button class="apply" type="button" @click="applyVoucher">
            Apply
          </button>
        </div>

        <div class="input-wrapper" v-if="this.charity?.id">
          <Icon
            :path="mdiHandHeart"
            :size="22"
            fillColor="#0b7162"
            class="donation__icon" />
          <div class="checkbox-field">
            <input
              type="checkbox"
              placeholder="Donation"
              id="donation"
              v-model="form.donation.value"
              @change="updateDonation"
              name="donation" />

            name="donation" />

            <label for="donation">
              Make a one-time donation of
              {{ this.formatMoney(donationAmount) }} to
              <a
                v-if="charity.url"
                target="blank"
                :href="charity.url"
                class="donation__link">
                {{ charity.name }}
              </a>

              <template v-else>{{ charity.name }}</template>
            </label>
          </div>
        </div>
      </form>
      <CartSummary
        class="payment-summary"
        :hideOrderDetails="true"
        :serviceId="serviceId"
        :donationScreen="true"
        @blockCheckoutContinueButton="this.ifWaitingResponse = true"
        @unblockCheckoutContinueButton="this.ifWaitingResponse = false" />
    </div>
    <AccordionFooter
      v-if="open"
      class="checkout-promotion__footer"
      :forwardDisabled="!validate()"
      @navigate="(ev) => $emit('navigate', ev)" />
  </div>
</template>

<script>
  import store from "../../store";
  import AccordionHeader from "@/components/Accordion/AccordionHeader";
  import AccordionFooter from "@/components/Accordion/AccordionFooter";
  import {
    mdiTicketPercent,
    mdiCheckCircle,
    mdiTag,
    mdiHandHeart,
  } from "@mdi/js";
  import CartSummary from "@/components/CartSummary";
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        serviceId: parseInt(this.$route.params.serviceId),
        cartId: parseInt(this.$route.params.cartId),
        form: {
          voucherCode: { value: null, errorMsg: null },
          donation: { value: null, errorMsg: null },
        },
        donationAmount: 0.5,
        charity: {},
        mdiTicketPercent,
        mdiCheckCircle,
        mdiHandHeart,
        mdiTag,
      };
    },

    props: {
      data: {
        type: Object,
      },
      open: {
        type: Boolean,
      },
      index: {
        type: Number,
      },
    },

    watch: {
      getCartSummary: function () {
        this.form.donation.value =
          this.getCartSummary?.charityDonations?.amount > 0;
      },
    },

    emits: ["navigate"],

    components: {
      AccordionHeader,
      AccordionFooter,
      CartSummary,
    },

    computed: {
      ...mapGetters({
        getCartSummary: "cart/getSummary",
      }),

      statusIcon() {
        if (this.validate()) {
          return mdiCheckCircle;
        }

        return mdiTicketPercent;
      },

      completed() {
        return this.data?.summary?.paymentPending === 0 && this.charity == {};
      },
    },

    methods: {
      // [VALIDATE] Fires once when the accordian loads to determine current step. Fires whenever the tab attempts to open.
      validate() {
        return store.getters["cart/getQueueIsEnd"];
      },

      updateDonation() {
        store.dispatch("cart/upsertDonation", {
          charityId: this.charity.id,
          amount: this.form.donation.value ? this.donationAmount : 0,
        });
      },

      applyVoucher() {
        // Installed vue-debounce, just needs enabling so this applies auto after x ms
        if (!this.form.voucherCode?.value?.trim()) return;

        store.dispatch("cart/upsertVoucher", this.form.voucherCode.value);
      },

      // [SETUP] Fires once at the start if this tab is open.
      async enter() {
        if (this.data.service?.charity?.id) {
          this.charity = await store.getters["repoCharities/getById"](
            this.data.service.charity?.id
          );
        }
      },

      // [POLL] Fires immediately after setup, and then every 5 seconds after.
      poll() {},
    },
  };
</script>

<style lang="scss" scoped>
  .checkout-promotion {
    width: 100%;

    &__body {
      width: 100%;

      form {
        .input-wrapper {
          @include flex($dir: row, $jc: flex-start, $ai: center);
          max-width: 500px;
          border: 1px solid $col_gray;
          border-radius: 0.25rem;
          padding: 0.5rem 1rem 0.5rem;
          margin-bottom: 0.5rem;
          background: white;
          flex-wrap: wrap;

          .field {
            position: relative;
            top: -0.25rem;
            flex: 1;
          }

          .error-msg {
            width: 100%;
            font-size: 0.875rem;
            margin-top: 0;
            color: $col_error;
          }

          input {
            border: 0;
            border-bottom: 1px solid $col_gray;
          }

          .donation__icon {
            svg {
              fill: $col_beta-darker;
            }
          }
        }

        .checkbox-field {
          @include flex($dir: row, $jc: flex-start, $ai: center);

          input[type="checkbox"] {
            flex: 0;
            padding: 0;
            margin-bottom: 0;
          }

          label {
            margin-bottom: 0;
          }
        }
      }
    }

    button.apply {
      @include outlined-button(
        $dir: column,
        $col: $col_beta-darker,
        $p: 0.25rem,
        $h: 2rem
      );
      min-width: 100px;
    }
  }
</style>
