<template>
  <button :class="style" type="button">
    <slot name="icon">
      <Icon :path="path" :size="18" />
    </slot>
    <span>{{ text }}</span>
  </button>
</template>

<script>
import { mdiCircleOutline } from "@mdi/js";

export default {
  name: "Button",
  data() {
    return {
      mdiCircleOutline,
    };
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    path: {
      type: String, 
      default: mdiCircleOutline
    },
    style: {
      type: Object,
      default() {
        return { button: true, primary: true, small: false, full: false };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  color: $col_black;
  background-color: $col_white;
  border: 0;
  border-radius: 10px;
  padding: 0.75rem 0.5rem;
  font-size: 1rem;
  display: flex;
  flex: 1;
  font-weight: 400;
  flex-direction: column;
  flex-basis: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.15s;

  span {
    font-weight: 400;
  }

  &:hover {
    color: $col_white;
    background-color: $col_beta-lighter;
  }

  &.active {
    color: $col_white;
    background: $col_beta-darker;
  }

  &:disabled,
  &[disabled] {
    background: #eaeaea !important;
    color: #ffffff !important;
    border: 0 !important;
    cursor: not-allowed;
  }

  &.outlined {
    background-color: #fff;
    border: 1px solid $col_beta;
    color: $col_beta;

    &:hover {
      background-color: $col_beta;
      color: $col_white;
    }
  }

  &.small {
    padding: 0.25rem 0.75rem;
    font-size: 0.7rem;
  }

  &.center {
    margin: 0 auto;
    display: block;
  }

  &.round {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    min-width: initial;

    .material-design-icon {
      height: 26px;
    }
  }
}

.icon {
  padding-bottom: 0.25rem;
}</style>
